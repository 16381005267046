import React from 'react'
import styles from './footer.module.css';
import { Iconq01, Iconq02, Iconq03 } from '../Svg';
const Footer = () => {
  return (
    <div className={styles.container}>
         <div className={styles.contant}>
         <div className={styles.section}>
          <h2 className={styles.logo1}>  <a className={styles.logoName}>  <div className={styles.maincontainer}>
              <img src="./assets/images/Footerlogo.png" className={styles.mainImg} />
            </div></a></h2>
         </div>
         <div className={styles.section}>
  <h2 className={styles.heading}>Pages</h2>
  <div className={styles.pages}>
    <a href="/" className={styles.pageLink}>Home</a>
    <a href="/about" className={styles.pageLink}>About</a>
    <a href="/services" className={styles.pageLink}>Services</a>
    <a href="/services" className={styles.pageLink}>Contact us</a>
  </div>
</div>

         <div className={styles.section}>
          <h2 className={styles.heading}>Useful links </h2>
          <div className={styles.pages}>
    <a href="E-Traveller" className={styles.pageLink}>Careers</a>
    <a href="/privacy-policy" className={styles.pageLink}>Privacy policy</a>

    </div>
         </div>
         <div className={styles.section}>
          <h2 className={styles.heading}>Contact </h2>
          <div className={styles.pages}>
    <p  className={styles.pageLink}>+91-81233 11094</p>
    <p  className={styles.pageLink}>info@aradeeventures.com</p>
    <p  className={styles.pageLink}>Bangalore, Karnataka, India</p>
    <div className={styles.icons}>  <a href="https://www.facebook.com/profile.php?id=61563804877791&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
        <Iconq01 />
      </a>  <Iconq02/>  <Iconq03/> 
    </div>  

    </div>
         </div>
        
         </div>
         <div className={styles.contant1}>
<hr className={styles.main}></hr>
<p className={styles.contant2}>
© 2024 aradee ventures. All Rights Reserved
</p>
         </div>
    </div>
  )
}

export default Footer