
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/header/Header';

import Home from './components/home/Home'
import About from "./components/about/About"
import Services from './components/services/Services';
import Cantact from './components/contact/Cantact';
import Privacy from './components/privacy/Privacy';
import EtravellerFst from './smallcomponents/e-travellerFst/EtravellerFst';
import Sample from './smallcomponents/sample/Sample';
import SampleDem from './smallcomponents/SampleDemo/SampleDem';
import SampleGet from './smallcomponents/sampleGet/SampleGet';
import Footer from './components/footer/Footer';
import Business from './smallcomponents/business/Business';
 

function App() {
  return (
    <BrowserRouter>
    <Header/>
      <Routes>
     
      <Route path='/' element={<Home />} />
      <Route path='/about' element={<About />} />
      <Route path='/services' element={<Services />} />
      <Route path='/contact' element={<Cantact />} />
      <Route path='/privacy-policy' element={<Privacy />} />
      <Route path='E-Traveller' element={<EtravellerFst />} />
      
      <Route path='/bca' element={<SampleDem />} />
      <Route path='/cab' element={<SampleGet />} />
   
     
      </Routes>
    
      <Footer/>
      </BrowserRouter>
  );
}

export default App;
